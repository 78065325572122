import { FETCH_DETAILS, FETCH_RESERVATIONS, RESERVATION_ERROR, RESERVATION_SUCCESS, SET_DETAILS, SET_RESERVATIONS } from "./actionTypes"

export const fetchReservations = data => {
  return {
    type: FETCH_RESERVATIONS,
    payload: data
  }
}

export const setReservations = data => {
  return {
    type: SET_RESERVATIONS,
    payload: data,
  }
}

export const fetchDetails = id => {
  return {
    type: FETCH_DETAILS,
    payload: id
  }
}

export const setDetails = data => {
  return {
    type: SET_DETAILS,
    payload: data,
  }
}

// export const addReservation = data => {
//   return {
//     type: ADD_RESERVATION,
//     payload: data,
//   }
// }

// export const editReservation = data => {
//   return {
//     type: EDIT_RESERVATION,
//     payload: data,
//   }
// }

// export const removeReservation = id => {
//   return {
//     type: DELETE_RESERVATION,
//     payload: id,
//   }
// }

export const reservationSuccess = msg => {
  return {
    type: RESERVATION_SUCCESS,
    payload: msg,
  }
}

export const reservationError = error => {
  return {
    type: RESERVATION_ERROR,
    payload: error,
  }
}

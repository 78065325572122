import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";


// Authentication
import forgetPassword from "./auth/forgetpwd/reducer";
import login from "./auth/login/reducer";
import profile from "./auth/profile/reducer";
import restaurant from "./resto/reducer";
import reservation from "./reservation/reducer";
import table from "./table/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  forgetPassword,
  login,
  profile,
  restaurant,
  reservation,
  table,
});

export default rootReducer;

import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import { addShift, editShift, removeShift } from '../../store/actions';
import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";

const Shifts = ({ data }) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [name, setName] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [q, setQ] = useState('');
  const [shifts, setShifts] = useState([]);

  const [current, setCurrent] = useState(null);

  const [modalType, setModalType] = useState('add');

  const dispatch = useDispatch();

  const toggleModal = data => {
    setCurrent(data)
    setModalType(data ? 'edit' : 'add');
    setName(data?.name || '')
    setFrom(data?.hour_begin || '')
    setTo(data?.hour_end || '')

    return setModal(!modal);
  }

  const toggleModal1 = data => {
    setCurrent(data)
    setModal1(true)
  }

  const user = JSON.parse(localStorage.authUser)
  const restoId = user.resto

  const submitModal = () => {
    if (modalType === 'add') dispatch(addShift({ id: restoId, name, hourB: from, hourE: to }))
    if (modalType === 'edit') dispatch(editShift({ id: current.id, name, hourB: from, hourE: to }))
    return setModal(false)
  }

  const deleteShift = () => {
    dispatch(removeShift(current.id))
    return setModal1(false)
  }

  const setTime = (date, fromOrTo) => {
    if (date.length > 0) {
      // Format to "HH:MM" using JavaScript's Date methods
      const hours = date[0].getHours().toString().padStart(2, '0');
      const minutes = date[0].getMinutes().toString().padStart(2, '0');
      if (fromOrTo === 'from') return setFrom(`${hours}:${minutes}`);
      if (fromOrTo === 'to') return setTo(`${hours}:${minutes}`);
    }
  };

  useEffect(() => {
    if (data && q) {
      const filteredShifts = data.filter(el => {
        const name = el.name.toLowerCase();
        return name.includes(q.toLowerCase())
      })
      return setShifts(filteredShifts) 
    }
    else setShifts(data)
  }, [q, data?.length])

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => row.name,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Start time</span>,
            selector: row => row.hour_begin,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>End time</span>,
            selector: row => row.hour_end,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: (cell) => {
                return (
                    <>
                        <button onClick={() => toggleModal(cell)} className="btn btn-sm me-2 shadow"><i className="ri-edit-line align-bottom text-dark"></i></button>
                        <button onClick={() => toggleModal1(cell)} className="btn btn-sm me-2 shadow"><i className="ri-delete-bin-line align-bottom text-danger"></i></button>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Row>
                <Col md={6} />
                <Col md={6}>
                    <div className="d-flex align-items-center justify-content-end">
                        <form className="app-search d-none d-lg-block p-0">
                            <div className="position-relative">
                            <Input
                              value={q}
                              onChange={e => setQ(e.target.value)}
                              type="text"
                              placeholder="Search..."
                              name="q" />
                            <span className="ri-search-line"></span>
                            </div>
                        </form>
                        <Button onClick={() => toggleModal()} color="primary" className="add-btn ms-2" id="create-btn"><i className="ri-add-line align-bottom me-1"></i> Add</Button>
                    </div>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={shifts}
                pagination
            />

            <Modal
                isOpen={modal}
                toggle={() => { toggleModal() }}
                centered
                scrollable={true}
                size="lg"
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    {modalType === 'add' ? 'Add user' : 'Update shift'}
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ maxHeight: 400 }}>
                <form
                    onSubmit={(e) => {
                    e.preventDefault();
                    submitModal();
                    return false;
                    }}
                >
                    <FormGroup>
                        <Label for="email">Name</Label>
                        <Input value={name} onChange={e => setName(e.target.value)} type="text" name="name" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="from" className="d-block">From</Label>
                        <Flatpickr
                          value={from}
                          onChange={val => setTime(val, 'from')}
                          options={{
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i', // Format to only show hour and minute
                            time_24hr: true // Use 24-hour time format
                          }}
                          style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="to" className="d-block">To</Label>
                        <Flatpickr
                          value={to}
                          onChange={val => setTime(val, 'to')}
                          options={{
                            minDate: from,
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i', // Format to only show hour and minute
                            time_24hr: true // Use 24-hour time format
                          }}
                          style={{ padding: 7, borderStyle: 'solid', borderRadius: 5, borderColor: '#eff2f7', width: '100%' }}
                        />
                    </FormGroup>
                    <button disabled={!name || !from || !to} type="submit" class="btn btn-primary mt-4">{modalType === 'add' ? 'Add' : 'Save changes'}</button>
                </form>
                </div>
            </Modal>

            <Modal
              isOpen={modal1}
              toggle={() => { toggleModal1() }}
              centered
              >
              <div className="modal-header">
                  <h5 className="modal-title mt-0">
                  Delete shift
                  </h5>
                  <button
                  type="button"
                  onClick={() => { setModal1(false) }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  >
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body d-flex justify-content-center" style={{ maxHeight: 400 }}>
                <h5>Do you want to delete <strong>{current?.name}</strong> shift?</h5>
              </div>
              <div className="modal-footer d-flex justify-content-center">
              <button onClick={() => deleteShift()} type="submit" class="btn btn-danger px-4">Yes</button>
              <button onClick={() => setModal1(false)} type="submit" class="btn btn-secondary px-4">No</button>

              </div>
            </Modal>
        </>
    );
};

export default Shifts;
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";

const Details = ({ info }) => {
    const details = useSelector(state => state.reservation.details)

    useEffect(() => {
        console.log(details)
    }, [details])
    return (
        <Row>
            <Col sm={12} xl={6}>
                <Card>
                    <CardBody className="bg-light">
                    <div className="d-flex align-items-center">
                        <img
                            className="border border-primary border-1 rounded me-2"
                            width={90}
                            height={90}
                            src={'https://yourteachingmentor.com/wp-content/uploads/2020/12/istockphoto-1223671392-612x612-1.jpg'}
                            alt="Client avatar"
                        />
                        <div>
                            <h5 className="m-0">{info.t_user.firstname} {info.t_user.lastname}</h5>
                            <span className="d-block">{info.t_user.phone}</span>
                            <span className="d-block">{info.t_user.email}</span>
                            {/* <p>{info.t_user.city} {info.t_user.zipcode}</p> */}
                            <p>{details.t_user?.t_address?.city} {details.t_user?.t_address?.zipcode}</p>
                        </div>
                    </div>
                    </CardBody>
                </Card>
            </Col>

            <Col sm={12} xl={6}>
                <Card>
                    <CardBody className="bg-light">
                        <div className="d-flex align-items-center justify-content-between">
                            <h5># {info.ref}</h5>
                            <span className="badge p-1 mb-2" style={{ backgroundColor: info.t_status_items.color }}>
                                <i className={`mdi me-1 ${info.t_status_items.iconName}`} />
                                {info.t_status_items?.name}
                            </span>
                        </div>
                        <h6>Covers: <strong>{info.nb_couvert}</strong></h6>
                        <h6>Date and time: <strong>{info.resa_date} at {info.resa_hour}</strong></h6>
                        <h6>Source: <strong>{info.reservationType}</strong></h6>
                    </CardBody>
                </Card>
            </Col>
            <Col sm={12} xl={12}>
                <Card>
                    <CardBody className="bg-light">
                        <h5>Notes</h5>
                        <span>{info.note || 'No notes yet..'} </span>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default Details;
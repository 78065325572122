import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardTitle, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Modal, Row } from 'reactstrap';
import "flatpickr/dist/themes/material_green.css";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import "flatpickr/dist/themes/material_green.css";
import _ from 'lodash';
import { fetchRoom, fetchRooms, fetchTables } from '../../store/table/actions';
import Details from './details';

const Table = () => {
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [q, setQ] = useState('');
  const dispatch = useDispatch();
  const tables = useSelector(state => state.table?.data || [])
  const rooms = useSelector(state => state.table?.rooms || [])
  const loading = useSelector(state => state.table?.loading || false)
  const [sOpen, setSOpen] = useState(false);
  const [sOpen1, setSOpen1] = useState(false);
  const [fTables, setFTables] = useState([]);
//   const [page, setPage] = useState(1);
//   const [perPage, setPerPage] = useState(10);
  const [room, setRoom] = useState({ name: 'All rooms', id: null })
  const [shape, setShape] = useState({ name: 'All shapes', icon: 'mdi-shape-outline', id: null })

  const shapes = [
    { name: 'All shapes', icon: 'mdi-shape-outline', id: null },
    { name: 'Square', icon: 'mdi-checkbox-blank-outline', id: 1 },
    { name: 'Circle', icon: 'mdi-checkbox-blank-circle-outline', id: 2 },
    // { name: 'Triangle', icon: 'mdi-triangle-outline', id: 1 },
    // { name: 'Rectangle', icon: 'mdi-rectangle-outline', id: 3 },
    // { name: 'Pentagon', icon: 'mdi-pentagon-outline', id: 4 },
    // { name: 'Hexagon', icon: 'mdi-hexagon-outline', id: 5 },
    // { name: 'Octagon', icon: 'mdi-octagon-outline', id: 6 },
  ]

  const toggleModal = data => {
    if (data) dispatch(fetchRoom(data.room))
    setCurrent(data || null)
    return setModal(!modal);
  }

  useEffect(() => {
    dispatch(fetchTables({ search: q, shape_id: shape.id, room_id: room?.id }))
  }, [])

  useEffect(() => {
    dispatch(fetchRooms())
  }, [])

  useEffect(() => {
    if (tables) {
      if (q) {
        console.log('q')
        const filteredTables = fTables.filter(el => {
          const fullName = `${el.name} ${el.t_room?.name} ${el.cover}`.toString().toLowerCase();
          return fullName.includes(q.toLowerCase())
        })
        setFTables(filteredTables) 
      }
      else if (room.id) {
        console.log('room.id')
        const filteredTables = tables.filter(el => el.room === room.id)
        setFTables(filteredTables)
      }
      else if (shape.id) {
        console.log('shape.id')
        const filteredTables = tables.filter(el => el.table_form === shape.id)
        setFTables(filteredTables)
      }
      else setFTables(tables)
    }
    else setFTables(tables)
  }, [tables, q, room, shape])

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => row.name,
            sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Room</span>,
          selector: cell => {
            return (
              <button onClick={() => toggleModal(cell)} type="button" className="btn btn-sm btn-outline-secondary px-4 btn-sm me-3">
                {cell.t_room?.name}
              </button>

            )
          },
          sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Shape</span>,
            selector: row => row.table_form,
            sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Covers</span>,
          selector: row => row.nb_couvert,
          sortable: true
      },
      {
        name: <span className='font-weight-bold fs-13'>Max covers</span>,
        selector: row => row.max_nb_couvert,
        sortable: true
      },        
    ];

    return (
        <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="My restaurant" breadcrumbItem="Tables" />
            { /* Tables */ }
            <Card>
              <CardBody>
              <Row>
                <Col md={6}>
                  <Dropdown
                    isOpen={sOpen}
                    toggle={() => setSOpen(!sOpen)}
                    className="d-inline-block d-sm-inline-block me-2"
                  >
                    <DropdownToggle className="btn btn-outline-primary waves-effect" tag="button">
                    <span className="text-nowrap">
                        {/* <i className={`mdi me-1 ${status.iconName}`}></i> */}
                        {room.name}
                        <i className="mdi ms-1 mdi-chevron-down"></i>
                    </span>
                    </DropdownToggle>
                    <DropdownMenu className="language-switch dropdown-menu-start">
                    {rooms.map(el => (
                        <DropdownItem
                          key={el.id}
                          onClick={() => setRoom(el)}
                          className={`notify-item ${ el.id === room.id ? "active" : "none" }`}
                        >
                          {/* <i className={`mdi me-1 ${el.iconName} ${el.id === status.id && 'text-primary'}`}></i> */}
                          <span className={`align-middle ${el.id === room.id && 'text-primary'}`}>{el.name}</span>
                      </DropdownItem>
                    ))}
                    </DropdownMenu>
                  </Dropdown>
                  <Dropdown
                    isOpen={sOpen1}
                    toggle={() => setSOpen1(!sOpen1)}
                    className="d-inline-block d-sm-inline-block"
                >
                    <DropdownToggle className="btn btn-outline-primary waves-effect" tag="button">
                    <span className="text-nowrap">
                        <i className={`mdi me-1 ${shape.icon}`}></i>
                        {shape.name}
                        <i className="mdi ms-1 mdi-chevron-down"></i>
                    </span>
                    </DropdownToggle>
                    <DropdownMenu className="language-switch dropdown-menu-start">
                    {shapes.map(el => (
                        <DropdownItem
                          key={el.id}
                          onClick={() => setShape(el)}
                          className={`notify-item ${ el.id === room.id ? "active" : "none" }`}
                        >
                          <i className={`mdi me-1 ${el.icon} ${el.id === shape.id && 'text-primary'}`}></i>
                          <span className={`align-middle ${el.id === shape.id && 'text-primary'}`}>{el.name}</span>
                      </DropdownItem>
                    ))}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-center justify-content-end">
                      <form className="app-search d-none d-lg-block p-0 me-2" style={{ width: '100%' }}>
                          <div className="position-relative">
                          <Input
                            value={q}
                            onChange={e => setQ(e.target.value)}
                            type="text"
                            debounce={500}
                            placeholder="Search by name, room, covers..."
                            name="q" />
                          <span className="ri-search-line"></span>
                          </div>
                      </form>
                      <button onClick={() => toggleModal()} type="button" className="btn btn-primary text-nowrap">
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add room
                      </button>
                  </div>
                </Col>
            </Row>
            <DataTable
                columns={columns}
                data={fTables}
                pagination
                // paginationServer
                // paginationDefaultPage={page}
                // paginationPerPage={perPage}
                // onChangePage={setPage}
                // onChangeRowsPerPage={setPerPage}
                progressPending={loading}
            />

            <Modal
                isOpen={modal}
                toggle={() => { toggleModal() }}
                centered
                scrollable={true}
                size="lg"
                >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">
                    {current ? 'Update' : 'Add'} room
                    </h5>
                    <button
                    type="button"
                    onClick={() => { setModal(false) }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body" style={{ maxHeight: 400 }}>
                    <Details info={current} shapes={shapes.filter(el => el.id)} />
                </div>
            </Modal>
              </CardBody>
            </Card>
  
          </Container>
        </div>
      </React.Fragment>
    );
};

export default Table;
import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();
// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};
const user = getLoggedInUser();

// Login Method
const postLogin = data => api.create(url.POST_LOGIN, data);

// postForgetPwd
const postForgetPwd = data => api.create(url.POST_PASSWORD_FORGET, data);

// Profile
const getProfile = () => api.get(url.GET_FETCH_PROFILE);
const putProfile = data => api.create(`${url.POST_PROFILE}/${user.id}`, data);
const updateEmail = data => api.update(`${url.PUT_PROFILE_EMAIL}/${user.id}`, data);
const updatePassword = data => api.update(`${url.PUT_PROFILE_PASSWORD}/${user.id}`, data);

// Restaurant
const getResto = () => api.get(url.GET_FETCH_RESTO);
const putRestoInfo = data => api.update(`${url.PUT_RESTO_INFO}/${user.resto}`, data);
const putRestoContact = data => api.update(`${url.PUT_RESTO_CONTACT}/${user.resto}`, data);
const putRestoAddress = data => api.update(`${url.PUT_RESTO_ADDRESS}/${user.resto}`, data);
const getKitchen = () => api.get(url.GET_FETCH_KITCHEN);

// Users
const putRestoUser = data => api.update(`${url.PUT_RESTO_USER}/${data.id}`, {
  firstname: data.firstname,
  lastname: data.lastname,
  role: data.role,
  email: data.email,
  phone: data.phone,
  password: data.password,
});
const postRestoUser = data => {api.create(`${url.POST_RESTO_USER}/${data.id}`, {
  firstname: data.firstname,
  lastname: data.lastname,
  role: data.role,
  email: data.email,
  phone: data.phone,
  password: data.password,
})};
const deleteRestoUser = id => api.delete(`${url.DELETE_RESTO_USER}/${id}`);
const getRoles = () => api.get(url.GET_FETCH_ROLES);

// Shifts
const putShift = data => api.update(`${url.PUT_SHIFT}/${data.id}`, {
  hourB: data.hourB,
  hourE: data.hourE,
  name: data.name,
});

const postShift = data => api.create(`${url.POST_SHIFT}/${data.id}`, {
  hourB: data.hourB,
  hourE: data.hourE,
  name: data.name,
});
const deleteShift = id => api.delete(`${url.DELETE_SHIFT}/${id}`);

// Kitchens
const postKitchen = data => api.create(url.POST_KITCHEN, data);
const deleteKitchen = id => api.delete(`${url.DELETE_KITCHEN}/${id}`);

// Reservations
const getReservations = data => api.get(url.GET_FETCH_RESERVATIONS, { params: data });
const getDetails = id => api.get(`${url.GET_FETCH_DETAILS}/${id}`);

// Tables & Rooms
const getTables = data => api.get(url.GET_FETCH_TABLES, { params: { if: user.resto, ...data } })
const postRoom = data => api.create(url.POST_ROOM, data);
const putRoom = data => api.update(`${url.PUT_ROOM}/${data.id}`, data);
const deleteRoom = id => api.delete(`${url.DELETE_ROOM}/${id}`);
const getRooms = () => api.get(`${url.GET_FETCH_ROOMS}/${user.resto}`);
const getRoom = id => api.get(`${url.GET_FETCH_ROOM}/${id}`);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  postForgetPwd,
  getProfile,
  putProfile,
  updateEmail,
  updatePassword,

  // Restaurant
  getResto,
  putRestoInfo,
  putRestoAddress,
  putRestoContact,
  getKitchen,

  // Users
  putRestoUser,
  postRestoUser,
  deleteRestoUser,
  getRoles,

  // Shifts
  putShift,
  postShift,
  deleteShift,

  // Kitchens
  postKitchen,
  deleteKitchen,

  // Reservations
  getReservations,
  getDetails,

  // Tables
  getTables,
  postRoom,
  putRoom,
  deleteRoom,
  getRooms,
  getRoom,
};

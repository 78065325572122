import { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Nav, NavItem, Row, TabContent, TabPane, NavLink } from "reactstrap";
import classnames from "classnames";

const Approval = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [selected, setSelected] = useState({});
  const approvals = [
      {
        id: 1,
        title: 'Today',
        data: [
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
        ]
      },
      {
        id: 2,
        title: 'Tomorow',
        data: [
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
        ]
      },
      {
        id: 3,
        title: 'Next week',
        data: [
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'Canceled', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
          { status: 'New reservation', date: '7-10-2004', hour: '17: 00', covers: 2, created: '1-10-2024', user: { firstname: 'Kamal', lastname: 'A', email: 'kamal@gmail.com', phone: '+21298342048', city: 'Agadir', zipcode: '8000' } },
        ]
      },
  ]

    useEffect(() => {
      setSelected(approvals[0])
    }, [])

    useEffect(() => {
      console.log(selected)
    }, [activeTab])
    
    return (
      <>
        {approvals.map(element => (
          <Card key={element.id}>
            <CardBody>
            <CardTitle>{element.title}</CardTitle>
            {element.data?.map((el, index) => (
              <TabPane key={index} tabId={activeTab}>
                <Row className="align-items-center p-2 border border-b">
                  <Col md={6} sm={12}>
                    <div className="d-flex align-items-center mb-1 align-items-center">
                      <span className="badge badge-soft-secondary me-2 p-2"> {el.user?.firstname} </span>
                      <span className="badge badge-soft-info p-2"> {el.status} </span>
                    </div>
                    <div className="d-flex align-items-center">
                      <h5 className="d-flex align-items-center me-4 text-dark"> <i className="ri-calendar-line me-1"></i>{el.date} at {el.hour}</h5>
                      <h5 className="d-flex align-items-center me-4 text-dark"> <i className="ri-restaurant-2-line me-1"></i>{el.covers}</h5>
                      <h5 className="d-flex align-items-center me-4 text-dark"> <i className="ri-time-line me-1"></i>Sent at {el.created}</h5>
                    </div>
                  </Col>
                  <Col md={6} sm={12}>
                    <div className="d-flex justify-content-end align-items-center mt-2">
                      <button type="button" className="btn btn-light px-4 btn-sm me-3"> <i className="ri-user-line"></i></button>
                      <button type="button" className="btn btn-success px-4 btn-sm me-3"><i className="ri-check-fill"></i>Accept</button>
                      <button type="button" className="btn btn-danger px-4 btn-sm me-3"><i className="ri-close-line"></i>Reject</button>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            ))}
            </CardBody>
          </Card>
        ))}
      </>
    )
}
export default Approval;
